import Vue from 'vue'
import App from './views/enter/App.vue'
import router from './unit/router'
import store from './store/app'
import axios from 'axios'



import 'lib-flexible/flexible.js' // Rem
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';//设置全局element-ui全局的css样式
Vue.use(ElementUI);

import './styles/css/reset.css';//设置全局element-ui全局的css样式
// import Alpha from './styles/js/Alpha-3.3.js'
// Vue.prototype.Alpha=Alpha;


// Vue.use(vueAxios, axios)
import Fun from "@/core/fun"; // 常用方法
Vue.prototype.fun = Fun;

Vue.prototype.axios = axios;
import './unit/http.js'
Vue.config.productionTip = false

import cnav from '@/components/nav/cnav.vue'
Vue.component('cnav',cnav)
import cfooter from '@/components/footer/cfooter.vue'
Vue.component('cfooter',cfooter)



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
router.afterEach(() => { document.body.scrollTop = 0; document.documentElement.scrollTop = 0;})