const module = {

    state : {

        menus : [],
    },
    actions : {
        
        edit_menus({commit}, menus) {

            commit('menus', menus);
        },
    },
    getters : {
        
        menus : state => state.menus,
    },
    mutations : {
        
        menus(state, menus) {

            state.menus = menus
        },

    }
}

export default module;




