import router from './router'
import Axios from 'axios'
// import {Message, MessageBox } from 'element-ui'
import { Loading, Message, MessageBox } from 'element-ui'



console.log(Loading)
// 超时时间
Axios.defaults.timeout = 5000
// http请求拦截器
// var loadinginstace
Axios.interceptors.request.use(config => {
   return config
}, error => {
   // Loading.close();
   Message.error({
         message: '网络不给力,请稍后再试'
     })
   return Promise.reject(error)
})
//   http响应拦截器
Axios.interceptors.response.use(data => {
    // 响应成功关闭loading
    // Loading.close();
    const code = data.data.mark;
    var err = data.data.err;
    if(code == 'no_login') { //未登录
        MessageBox.alert('请先登录3', '提示', {
            confirmButtonText: '确定',
            callback: () => {
				var host = window.location.host;
				var url = 'https://'+host+'/v/juma/official/login';
				window.location.replace(url);
                // router.replace({
                //     name: 'Login',
                //     query: {redirect: router.currentRoute.fullPath} //登录后再跳回此页面时要做的配置
                // })
            }
        });
    }else{
      if(err){
        console.log(err)
        // MessageBox.alert(code,'错误提示',{
        //   confirmButtonText: '确定'
        // });
      }
    }
    return data
}, error => {
    // Loading.close();
    Message.error({
         message: '网络不给力,请稍后再试'
     })
    return Promise.reject(error)
})