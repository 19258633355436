<template>
    <div>
        <div id="app">
            <router-view name="app"/>
        </div>
    </div>
</template>

<style lang="scss" type="">

    body{
        background-color: #F9F9F9;
        /*overflow: hidden;*/
    }
    #app{
        min-width: 1400px;
    }

</style>

<script>
export default {

    created() {

        // console.log(this.$route.path);

        // this.$router.push({ name: 'entrance', path: '/entrance'});
    }

}  
</script>
