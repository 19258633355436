import Vue from "vue";
import Router from "vue-router";
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> error)
}
Vue.use(Router);
const routers = [
    {
        path: "*",
        redirect: "/entrance"
    },{
        path: '/login',
        name: 'Login',
        components: {
            app: () => import('@/views/enter/login.vue')
        },
    },
    {
        path: '/entrance',
        name: 'entrance',
        components: {
            app: () => import('@/views/enter/entrance.vue')
        },
        children:[
            {
                path: '/index/index',
                name: 'index',
                components: {
                
                    entrance: () => import('@/views/index/index.vue')
                },
        
            },{//产品中心
                path: '/product/product',
                name: 'product',
                components: {
                
                    entrance: () => import('@/views/product/product.vue')
                },
        
            },{//定制服务
                path: '/custom/custom',
                name: 'custom',
                components: {
                
                    entrance: () => import('@/views/custom/custom.vue')
                },
        
            },{//活动详情
                path: '/product/event_details/event_details',
                name: 'event_details',
                components: {
                
                    entrance: () => import('@/views/product/event_details/event_details.vue')
                },
        
            },{//产品详情
                path: '/product/product_details/xmall_detail',
                name: 'xmall_detail',
                components: {
                
                    entrance: () => import('@/views/product/product_details/xmall_detail.vue')
                },
        
            },{//产品详情
                path: '/product/product_details/hkmall_detail',
                name: 'hkmall_detail',
                components: {
                
                    entrance: () => import('@/views/product/product_details/hkmall_detail.vue')
                },
        
            },{//产品详情
                path: '/product/product_details/fxmall_detail',
                name: 'fxmall_detail',
                components: {
                
                    entrance: () => import('@/views/product/product_details/fxmall_detail.vue')
                },
        
            },
            {//产品订购
                path: '/product/product_ordering/product_ordering',
                name: 'product_ordering',
                components: {
                
                    entrance: () => import('@/views/product/product_ordering/product_ordering.vue')
                },
        
            },{//模板中心
                path: '/mould/mould',
                name: 'mould',
                components: {
                
                    entrance: () => import('@/views/mould/mould.vue')
                },
        
            },{//客户案例
                path: '/case/case',
                name: 'case',
                components: {
                
                    entrance: () => import('@/views/case/case.vue')
                },
        
            },
            {//渠道合作
                path: '/partners/partners',
                name: 'partners',
                components: {
                
                    entrance: () => import('@/views/partners/partners.vue')
                },
        
            },
            {//关于我们
                path: '/about_us/about_us',
                name: 'about_us',
                components: {
                
                    entrance: () => import('@/views/about_us/about_us.vue')
                },
        
            },
            {//注册
                path: '/register/register',
                name: 'register',
                components: {
                
                    entrance: () => import('@/views/register/register.vue')
                },
        
            },
            {//注册
                path: '/register/re_success',
                name: 're_success',
                components: {
                
                    entrance: () => import('@/views/register/re_success.vue')
                },
        
            }
            
        ]
    },

];

// export default routers;

export default new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: routers
});