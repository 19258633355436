/**
 公共方法
 */
// const R = require("ramda");

var fun = {
  //数组分级===================================
  //this.fun.chunk(json.data.category,8);
  
  //键盘顶起页面后隐藏不回弹解决方案
  clearSrcoll() {
    var currentPosition, timer;
    var speed = 1; //页面滚动距离
    timer = setInterval(function() {
      currentPosition =
        document.documentElement.scrollTop || document.body.scrollTop;
      currentPosition -= speed;
      window.scrollTo(0, currentPosition); //页面向上滚动
      currentPosition += speed; //speed变量
      window.scrollTo(0, currentPosition); //页面向下滚动
      clearInterval(timer);
    }, 1);
  },
  // 计算总数
  sumUp(arr){
    var sum = 0;
    arr.map((val) => {
      // console.log(val,2323)
      sum += val.amount;
    })
    return sum;

  },
  setCookie(key, value, t) {
    let oDate = new Date();
    oDate.setDate(oDate.getDate() + t);
    document.cookie = encodeURI(key) + "=" + value + ";expires=" + oDate.toGMTString();
  },
  getCookie(key) {
    let arr1 = document.cookie.split("; ");
    for (let i = 0; i < arr1.length; i++) {
      let arr2 = arr1[i].split("=");
      if (arr2[0] == key) {
        return decodeURI(arr2[1]);
      }
    }
  }

};
export default fun;
