<template>
    <nav id="globalnav">
        <div class="globalnav-content">
            <ul class="nav-con-list">
                <li :class="{'main-color':menu_n=='index'}" class="nav-con-l-name" @click="navc('index')"><img src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/jumalogo.png"><span>丨深圳聚码科技有限公司</span></li>
                <li class="list-item" :class="{'main-color':menu_n=='index'}" @click="navc('index')">
                    <p class="chinese">首 页</p>
                    <p class="english">HOME</p>
                </li>
                <li class="list-item" :class="{'main-color':menu_n=='product'}" @click="navc('product')">
                    <p class="chinese">产品中心</p>
                    <p class="english">PRODUCT</p>
                </li>
                <li class="list-item" :class="{'main-color':menu_n=='custom'}" @click="navc('custom')">
                    <p class="chinese">定制服务</p>
                    <p class="english">CUSTOM</p>
                </li>
                <li class="list-item" :class="{'main-color':menu_n=='mould'}" @click="navc('mould')">
                    <p class="chinese">模板中心</p>
                    <p class="english">TEMPLATE
                    </p>
                </li>
                <li class="list-item" :class="{'main-color':menu_n=='case'}" @click="navc('case')">
                    <p class="chinese">客户案例</p>
                    <p class="english">CUSTOMER</p>
                </li>
                <li class="list-item" :class="{'main-color':menu_n=='partners'}" @click="navc('partners')">
                    <p class="chinese">渠道合作</p>
                    <p class="english">COOPERATION</p>
                </li>
                <li class="list-item" :class="{'main-color':menu_n=='about_us'}" @click="navc('about_us')">
                    <p class="chinese">关于我们</p>
                    <p class="english">COMPANY</p>
                </li>
                <li class="register list-item" @click="navc('register')">免费注册</li>
                <!-- <li class="login" v-if="is_login==1" @click="go_vclient">控制中心</li> -->
                <li :class="{'main-color':menu_n=='Login'}" @click="navc('Login')" class="login">登录</li>
            </ul>
        </div>
    </nav>
</template>

<script>



export default {
    name: 'cnav',
    props:{
        navindex: {
          type: String,
          required: true,
        }
    },
    data() {
        return {
            menu_n: '1',
            is_login: 0
        }
    },
    watch: {
        $route: {
            handler: function(val, oldVal){
              this.menu_n = val.name;
            },
            // 深度观察监听
            deep: true
        }
    },
    // computed:{
    //     is_login(){
    //         return this.fun.getCookie("login");
    //     },
    // },
    created() {
        
    },
    mounted(){
        this.menu_n = this.navindex;
        this.login();
    },
    methods: {
        navc(name){
            this.$router.push({name: name});
            this.menu_n = name;
        },
        login(){
            this.$nextTick(function () {
                // this.is_login = this.fun.getCookie("login");
                this.is_login = sessionStorage.getItem("login");
            })
            
        },
        go_vclient(){
            var host = window.location.host;
            var url = 'https://'+host+'/v/juma/client/index/intro';
            window.location.replace(url);
        }
    }
    
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss" type="text/css">
    #globalnav{
        width: 100%;
        min-width: 1200px;
        height: 75px;
        max-height: 75px;
        /*box-shadow: 0 2px 2px 0 #aaa;*/
        position: fixed;
		left: 0;
        z-index: 999;
        background-color: #fff;

    }
    .globalnav-content{
		min-width: 1200px;
        margin: 0 auto;
        max-width: 1374px;
        padding: 0 22px;
    }
    .nav-con-list{
        width: auto;
        height: 75px;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 14px;
        margin: 0 -25px;
        text-align: center;

    }
    .nav-con-list li{
        margin: 0 25px;
        cursor: pointer;
        margin-top: 17px;
    }
    .nav-con-list .english{
        font-size: 10px;
        font-weight: lighter;
        font-family: 'Lato-Light';
        padding-top: 0px;
        transform: scale(0.8);
    }
    .list-item:hover{
        color: #E79E0D!important;
    }
    .nav-con-list li img{
        width: 120px;
        height: auto;
    }
    .nav-con-list li.main-color{
        /*font-weight: 400;*/
    }
    .nav-con-list .nav-con-l-name{
        margin-right: auto!important;
        margin-top: 15px;
        display: flex;
        // align-items: flex-end;
        align-items: center;
    }
    .nav-con-l-name span{
        color: #333!important;
    }
    .nav-con-list .register{
        font-size: 13px;
        color: #E79E0D;
        margin-top: 12px;
    }
    .nav-con-list .register:hover{
        opacity: 0.8;
    }
    .nav-con-list .login{
        font-size: 13px;
        width: 88px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        border-radius: 39px;
        background-color: #E79E0D;
        color: #fff;
        margin-left: 0;
        letter-spacing: 2px;
        box-shadow: 2px 2px 2px 0 #aaaaaa;
        margin-top: 12px;
    }
    .nav-con-list .login:hover{
        opacity: 0.8;
    }
</style>