const module = {

    state : {

        is_login: false,
    },
    actions : {

    },
    getters : {
        
    },
    mutations : {
        
    }
}

export default module;




