<template>
  	<footer id="globalfooter">
  		<div class="globalfooter-content">
        <div class="footer-top">
          <img src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/jimalogo_footer.png">
          <ul class="nav-con-list">
              <li class="list-item" @click="navc('product')">
                  <p class="chinese">产品中心</p>
                  <p class="english">PRODUCT</p>
              </li>
              <li class="list-item" @click="navc('mould')">
                  <p class="chinese">模板中心</p>
                  <p class="english">TEMPLATE
                  </p>
              </li>
              <li class="list-item" @click="navc('case')">
                  <p class="chinese">客户案例</p>
                  <p class="english">CUSTOMER</p>
              </li>
              <li class="list-item" @click="navc('partners')">
                  <p class="chinese">渠道合作</p>
                  <p class="english">COOPERATION</p>
              </li>
              <li class="list-item" @click="navc('about_us')">
                  <p class="chinese">关于我们</p>
                  <p class="english">COMPANY</p>
              </li>
          </ul>
          <div class="img-box">
            <i class="iconfont icon-weibo"></i>
            <i class="iconfont icon-weixin"></i>
          </div>
        </div>
        <div class="information">
          <p><a class="underline" href="https://beian.miit.gov.cn/" target="_blank">粤ICP备19141105号</a> | © 2021 聚码 | 深圳聚码科技有限公司 | 版权所有</p>
          <p>COPYRIGHT © 2003-2021 WWW.GELEIYUN.COM ALL RIGHTS RESERVED</p>
        </div>



        <div class="footer-bottom">
            <div class="f-b-list">
              <div class="img img1"></div>
              <div class="fb-text">
                <p>工商网监</p>
                <p>电子标识</p>
              </div>
            </div>
            <div class="f-b-list">
              <div class="img img2"></div>
              <div class="fb-text">
                <p>经营性网站</p>
                <p>备案信息</p>
              </div>
            </div>
            <div class="f-b-list">
              <div class="img img3"></div>
              <div class="fb-text">
                <p>不良信息</p>
                <p>举报中心</p>
              </div>
            </div>
            <div class="f-b-list">
              <div class="img img4"></div>
              <div class="fb-text">
                <p>网上有害信息</p>
                <p>举报专区</p>
              </div>
            </div>
            <div class="f-b-list">
              <div class="img img5"></div>
              <div class="fb-text">
                <p>网站备案号</p>
                <p><a class="underline" href="https://beian.miit.gov.cn/" target="_blank">粤ICP备19141105号</a></p>
              </div>
            </div>
        </div>
  		</div>
  		
  	</footer>
</template>

<script>
export default {
  name: 'cmenu',
  data() {
    return {
    }
  },
  methods: {
    navc(name){
          this.$router.push({name: name});
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style type="text/css" scoped>
	#globalfooter{
      width: 100%;
      min-width: 1200px;
      /*height: 325px;*/
      /*max-height: 325px;*/
      background-color: #000;
      margin-top: 80px;
  }
  .globalfooter-content{
      /*height: 325px;*/
      margin: 0 auto;
      max-width: 1374px;
      padding: 0 22px 70px 22px;
      position: relative;
      overflow: hidden;

  }
  .footer-top{
    width: 100%;
    display: flex;
    align-items: flex-end;
    color: #FFFBFB;
    border-bottom: 0.1px solid #333;
    padding-bottom: 20px;
    margin-top: 60px;
  }
  .footer-top img{
    width: 138px;
    flex-shrink: 0;
  }
  .nav-con-list{
      display: flex;
      font-size: 14px;
      line-height: 14px;
      margin: 0 -25px;
      text-align: center;
      flex: 1;
      margin-left: 50px;
  }
  .nav-con-list li{
      margin: 0 25px;
      cursor: pointer;
      font-weight: 300;
  }
  .nav-con-list .english{
      font-size: 10px;
      font-weight: lighter;
      font-family: 'Lato-Light';
      padding-top: 0px;
      transform: scale(0.8);
  }
  .nav-con-list li img{
      width: 120px;
      height: auto;
  }
  .img-box{
    flex-shrink: 0;
  }
  .img-box i{
    font-size: 24px;
    margin-left: 20px;
    color: #FFFBFB;
    opacity: 0.7;
  }
  .information{
    margin-top: 46px;

  }
  .information p{
    font-size: 12px;
    line-height: 12px;
    color: #fff;
    opacity: 0.5;
    font-weight: 300;
    font-family: "Lato-Light";
    margin-top: 8px;
    /*transform: scale(0.9);*/
  }
  .footer-bottom{
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 50px;
  }
  .f-b-list{
    /*width: 20%;*/
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    color: #fff;
    font-weight: 300;
    position: relative;
    padding: 0 5%;
  }
  .f-b-list p{
    opacity: 0.5;
  }

  .f-b-list:first-child{
    padding-left: 0;
  }
  .underline{
    color: #fff;
  }
  .underline:hover{
    text-decoration: underline!important;
  }
  .f-b-list::after{
    content: '';
    width: 1px;
    height: 34px;
    background-color: #333333;
    position: absolute;
    right: 0;
  }
  .f-b-list:last-child::after{
    width: 0;
  }
  .img{
    width: 24px;
    height: 38px;
    background: url(https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/spr_common.png);
    background-repeat: no-repeat;
    margin-right: 10px;
    /*background-size: 100% 100%;*/
  }
  .img1{
    background-position: -10px 0;
  }
  .img2{
    background-position: -56px 0;
  }
  .img3{
    background-position: -102px 0;
  }
  .img4{
    background-position: -150px 0;
  }
  .img5{
    width: 30px;
    background-position: -192px 0;
  }







</style>